export default {
  meta: {
    title: "Документація — ADISA",
    description: "description",
    keywords: "ADISA"
  },
  title: "Сертифікати та документація",
  info: [ // resizable
    // ========== Инструкции на котлы========== Типы файлов: link и doc
    {
      caption: "Інструкції на котли",
      list: [ // resizable
        {
          type: "link",
          text: "Інструкція ADISA ADI LT.",
          link: "/resources/ADI-LT-Rus.pdf"
        },
        {
          type: "link",
          text: "Інструкція ADISA ADI CD.",
          link: "/resources/ADI-CD-Rus.pdf"
        },
        {
          type: "link",
          text: "Інструкція ADISA ADI MEGA.",
          link: "/resources/ADI-MEGA-CD-UKR.pdf"
        },
        {
          type: "link",
          text: "Інструкція із збирання ADISA ADI MEGA.",
          link: "/resources/ADI-MEGA-assembly.pdf"
        },
        {
          type: "link",
          text: "Інструкція ADISA Condexa PRO.",
          link: "/resources/ADI-Condexa-PRO-Rus.pdf"
        },
        {
          type: "link",
          text: "Каскад ADISA Condexa PRO.",
          link: "/resources/ADI-Condexa-PRO-Cascade_Rus.pdf"
        }
      ],
    },
    // ========== Сертификаты и разрешения ==========
    {
      caption: "Сертифікати та дозволи",
      list: [
        {
          type: "link",
          text: "Сертифікат відповідності.",
          link: "/resources/UA024C0555-20.pdf"
        },
        {
          type: "link",
          text: "Сертифікат експертизи типу (до 400кВт).",
          link: "/resources/UATR012C0553-20.pdf"
        },
        {
          type: "link",
          text: "Сертифікат експертизи типу (понад 400кВт).",
          link: "/resources/UATR012C0554-20.pdf"
        },
        {
          type: "link",
          text: "Дозвіл ADISA.",
          link: "/resources/ADISA-dozvil.pdf"
        }
      ]
    },
    // ========== Сервисному инженеру ==========
    {
      caption: "Сервісному інженеру",
      list: [
        {
          type: "link",
          text: "Навчання з експлуатації котлів ADISA.",
          link: "/resources/ADISA_education.pdf"
        },
        {
          type: "link",
          text: "Повна інструкція на контролер Siemens LMS.",
          link: "/resources/LMS-Rus.pdf"
        },
        {
          type: "link",
          text: "Знайомство з контролером Siemens LMS. Швидке введення в експлуатацію.",
          link: "/resources/LMS-fast-start.pdf"
        },
        {
          type: "link",
          text: "Перегляд історії помилок на новому дисплеї.",
          link: "/resources/History-error.pdf"
        },
        {
          type: "link",
          text: "Скидання помилок на новому дисплеї.",
          link: "/resources/Reset-Errors.pdf"
        },
        {
          type: "link",
          text: "Вибухова схема котлів ADISA ADI 70-105.",
          link: "/resources/shema_ADI-70-105.pdf"
        },
        {
          type: "link",
          text: "Вибухова схема котлів ADISA ADI 130-200.",
          link: "/resources/shema_ADI-130-200.pdf"
        },
        {
          type: "link",
          text: "Вибухова схема котлів ADISA ADI 250-275.",
          link: "/resources/shema_ADI-250-275.pdf"
        },
        {
          type: "link",
          text: "Вибухова схема котлів ADISA ADI 325-475.",
          link: "/resources/shema_ADI-325-475.pdf"
        },
        {
          type: "link",
          text: "Вибухова схема котлів ADISA ADI 550-750.",
          link: "/resources/shema_ADI-550-750.pdf"
        },
        {
          type: "link",
          text: "Вибухова схема котлів ADISA ADI 850-950.",
          link: "/resources/shema_ADI-850-950.pdf"
        }
      ]
    },
    // ========== Проектировщику ==========
    {
      caption: "Проектувальнику",
      list: [
        {
          type: "doc",
          text: "REVIT BIM моделі ADISA ADI CD.",
          link: "/resources/ADISA_ADI-CD_BIM_engl.zip"
        },
        {
          type: "link",
          text: "Витрата газу наведена до калорійності 8000ккал/м3.",
          link: "/resources/rashod-gaza.jpg"
        },
        {
          type: "link",
          text: "Площа поверхні теплообміну котлів ADISA.",
          link: "/resources/poverhnost-teploobmena.pdf"
        },
        {
          type: "link",
          text: "Рівень шуму котлів ADISA.",
          link: "/resources/noise-level-adisa.pdf"
        },
        {
          type: "link",
          text: "Приклад котельні 478кВт.",
          link: "/resources/proekt-478kwt.pdf"
        },
        {
          type: "link",
          text: "Приклад котельні 1090кВт.",
          link: "/resources/proekt-1090kwt.pdf"
        },
        {
          type: "link",
          text: "Приклад котельні 1.3МВт.",
          link: "/resources/proekt-1.3mwt.pdf"
        },
        {
          type: "link",
          text: "Приклад котельні 3.2МВт.",
          link: "/resources/proekt-3.2mwt.pdf"
        },
        {
          type: "link",
          text: "Приклад котельні 5МВт.",
          link: "/resources/proekt-5mwt.pdf"
        }
      ]
    }
    // ========== Add+ ==========
  ]
}
