export default {
  meta: {
    title: "Контакти — ADISA",
    description: "description",
    keywords: "ADISA"
  },
  header: "Регіональні представництва",
  labels: {
    address: 'Адреса',
    phone: 'Тел.',
    email: 'E-mail',
    web: 'Web'
  },
  offices: {
    mainOffice: {
      title: "Головний офіс:",
      office: { 
        address: "Адреса: м.Одеса, вул. Академіка Корольова, будинок 5/4",
        tels: [ // resizable
          "+38(050)333-88-77",
          "+38(050)333-88-83",
        ],
        emails: [ // resizable
          "adisa.ua@ukr.net"
        ]
      }
    },
    listOffices: {
      title: 'Регіональні представництва:',
      list: [ // resizable
        // ========== Івано-Франківська область ==========
        { city: "Івано-Франківська область",
          path: "ivanofrankivsk",
          addresses: [
            // ---------- Тега Інжиніринг ----------
            { officeName: "ТОВ “Тега Інжиніринг”",
              address: "м.Івано-Франківськ, вул.Дністровська, будинок 55",
              tels: [
                "+38(050)844-44-08"
              ],
              emails: [
                "igor@tega.com.ua"
              ]
            }
            // ---------- Add+ ----------
          ]
        },
        // ========== Київська область ==========
        { city: "Київська область",
          path: "kiev",
          addresses: [
            // ---------- Грін Апекс ----------
            { officeName: "ТОВ “Грін Апекс”",
              address: "м.Київ, вул. Скляренка Семена, будинок 17",
              tels: [
                "+38(044)583-11-98"
              ],
              emails: [
                "info@greenapex.com.ua"
              ],
              webs: [
                "www.greenapex.com.ua"
              ]
            },
            // ---------- МельситБуд ----------
            { officeName: "ТОВ “МельситБуд”",
              address: "м.Київ, провулок Куренівський, будинок 4/8-А",
              tels: [
                "+38(044)467-58-35",
                "+38(050)331-44-27"
              ],
              emails: [
                "melsit_bud@ukr.net"
              ]
            },
            // ---------- Гранд Енерджи ЛТД ----------
            { officeName: "ТОВ “Гранд Енерджи ЛТД”",
              address: "м.Київ, вул.Ліварська, будинок 7",
              tels: [
                "+38(067)367-76-92",
                "+38(099)534-56-38",
                "+38(044)465-62-39"
              ],
              emails: [
                "info@grandenergy.com.ua"
              ],
              webs: [
                "www.grandenergy.com.ua"
              ]
            },
            // ---------- Термоинжиниринг ----------
            { officeName: "ТОВ “Термоінжиніринг”",
              address: "м.Київ, вул.Інститутська, оф. 1/3",
              tels: [
                "+38(044)227-08-84",
                "+38(073)227-08-84"
              ],
              emails: [
                "info@termoeng.com.ua"
              ],
              webs: [
                "www.termoeng.com.ua"
              ]
            }
            // ---------- Add+ ----------
          ]
        },
        // ========== Львівська область ==========
        { city: "Львівська область",
          path: "lviv",
          addresses: [
            // ---------- Абеліт Плюс ----------
            { officeName: "ТОВ “Абеліт Плюс”",
              address: "м.Львів, проспект В'ячеслава Чорновола, будинок 73",
              tels: [
                "+38(067)673-15-52",
                "+38(050)315-59-21"
              ],
              emails: [
                "abelitplus@ukr.net"
              ]
            },
            // ---------- Енерго Консалт Груп ----------
            { officeName: "ТОВ “Енерго Консалт Груп”",
              address: "м.Львів, проспект Шевченка, будинок 11",
              tels: [
                "+38(093)489-51-91"
              ],
              emails: [
                "ssslviv@gmail.com",
                "ssslviv@ukr.net"
              ]
            }
            // ---------- Add+ ----------
          ]
        },
        // ========== Одеська область ==========
        { city: "Одеська область",
          path: "odessa",
          addresses: [
            // ---------- ТМ Інжиніринг ----------
            { officeName: "ТОВ “ТМ Інжиніринг”",
              address: "м.Роздільна, ул.Тираспольская, будинок 35Д",
              tels: [
                "+38(050)333-65-08"
              ],
              emails: [
                "ovkprom@gmail.com"
              ]
            }
            // ---------- Add+ ----------
          ]
        },
        // ========== Полтавська область ==========
        { city: "Полтавська область",
          path: "poltava",
          addresses: [
            // ---------- Стандартпромсервіс ----------
            { officeName: "ПП “Стандартпромсервіс”",
              address: "м.Полтава, провулок Великий, будинок 4а",
              tels: [
                "+38(093)392-01-99",
                "+38(099)661-21-83",
                "+38(053)250-26-99"
              ],
              emails: [
                "standart-sps@ukr.net"
              ],
              webs: [
                "www.s-p-s.top"
              ]
            }
            // ---------- Add+ ----------
          ]
        } 
        // ========== Add+ ==========
      ]
    }
  },

  form: {
    title: "ЗАЛИШИЛИСЯ ЗАПИТАННЯ?",
    labels: { 
      name: "Введіть ваше ім'я або назву організації",
      contact: "Введіть ваш e-mail або номер телефону",
      message: "Залиште повідомлення"
    },
    submitBtn: "ЗВ'ЯЗАТИСЯ З НАМИ",
    errors: {
      title: "Будь ласка, заповніть усі поля: ",
      fields: {
        name: {
          cantBeBlank: "Введіть ім'я або назва організації."
        },
        contact: {
          cantBeBlank: "Введіть e-mail або номер телефону.",
          invalidInput: "E-mail або номер телефону введений не вірно."
        },
        message: {
          cantBeBlank: "Введіть повідомлення."
        },
        captcha: {
          cantBeBlank: "Пройдіть перевірку reCAPTCHA."
        }
      }
    },
    success: {
      message: "Ваш запит успішно відправлений. Ми зв'яжемося з вами найближчим часом!"
    }
  }
};
