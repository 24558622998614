export default [ // resizable
  // ========== adi-lt ==========
  {
    name: "ADI LT",
    path: "adi-lt",
    meta: {
      title: "ADI LT — Обладнання — ADISA",
      description: "description",
      keywords: "ADISA"
    },
    description: "Конденсаційний газовий котел з нержавіючої сталі ККД 104%",
    features: [ // resizable
      "・Виняткова ефективність та енергозбереження.",
      "・EC сертифікат, 3 зірки ефективності до 104% ККД.",
      "・Потужність від 104 до 905кВт.",
      "・Модуляція горіння починаючи з 23% потужності, вбудований PID контролер.",
      "・Змінна швидкість вентилятора, газо-повітряне преміксне змішання Вентурі, модуляційний газовий клапан.",
      "・Теплообмінник котла виготовлений із нержавіючої сталі. Відмінна стійкість до корозії та високої температури.",
      "・Немає ліміту по температурі зворотної води.",
      "・Мінімальний рівень шуму, від 48.3 dB, вбудовані звукопоглинаючі панелі.",
      "・Низькі викиди: клас NOx = 5, NOx <10ppm, CO близько 47ppm.",
      "・Вогнетривкий сплав сітки преміксного пальника.",
      "・Компактні розміри та низька вага.",
      "・Мінімальне споживання електроенергії, від 14Вт.",
      "・Можливе дистанційне керування."
    ],
    docsIntro: "Посилання та документи:",
    docs: [ // resizable
      {
        type: "link",
        text: "Інструкція ADISA ADI LT.",
        link: "/resources/ADI-LT-Rus.pdf"
      },
      {
        type: "link",
        text: "Сертифікат відповідності.",
        link: "/resources/UA024C0555-20.pdf"
      },
      {
        type: "link",
        text: "Сертифікат експертизи типу (до 400кВт).",
        link: "/resources/UATR012C0553-20.pdf"
      },
      {
        type: "link",
        text: "Сертифікат експертизи типу (понад 400кВт).",
        link: "/resources/UATR012C0554-20.pdf"
      },
      {
        type: "link",
        text: "Дозвіл ADISA.",
        link: "/resources/ADISA-dozvil.pdf"
      }
    ],
    table: [ // resizable
      [
        "ADI LT", "Од.", "105", "130", "150", "200", "250", "275", "325", "400", "475", "550", "650", "750", "850", "950"
      ],
      [
        "Макс. потужність при 80/60ºC", "кВт", "104.3", "131.3", "149.7", "190.6", "230.7", "262.0", "322.0", "380.0", "464.0", "545.0", "616.0", "695.0", "804.0", "905.0"
      ],
      [
        "Мін. потужність при 50/30ºC", "кВт", "34.4", "43.3", "49.3", "62.8", "76.0", "84.8", "104.9", "124.4", "151,8", "236.3", "266.6", "299.4", "345.3", "386.3"
      ],
              [
        "Макс. витрата газу", "м3/г", "10.2", "12.8", "14.6", "18.6", "22.5", "25.1", "31.0", "36.8", "44.9", "52.4", "59.3", "67.0", "77.1", "87,2"
      ],
      [
        "Вага без води", "кг", "110", "112", "123", "139", "330", "350", "440", "445", "460", "480", "485", "485", "545", "545"
      ],
      [
        "Об'єм води", "л", "30", "30", "33", "36", "76", "85", "99", "106", "118", "120", "120", "120", "164", "164"
      ],
      [
        "Макс. електроспоживання", "Вт", "255", "268", "282", "256", "314", "259", "342", "445", "727", "668", "859", "1165", "1650", "1850"
      ],
      [
        "Живлення", "В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "380В", "380В"
      ],
      [
        "Ширина A", "мм", "350", "450", "450", "450", "660", "660", "810", "810", "810", "1040", "1040", "1040", "1040", "1040"
      ],
      [
        "Глубина B", "мм", "595", "595", "615", "635", "940", "940", "940", "940", "940", "940", "940", "940", "1083", "1083"
      ],
      [
        "Висота без опор H", "мм", "1110", "1110", "1110", "1110", "1583", "1583", "1583", "1583", "1583", "1628", "1628", "1628", "1628", "1628"
      ],
      [
        "Діаметр димоходу", "мм", "150", "150", "150", "150", "175", "175", "250", "250", "250", "350", "350", "350", "350", "350"
      ]
    ],
    preview: [ // resizable
      {
        images: [ // resizable
          {
            description: "",
            path: "/img/adilt1.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/adilt2.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/adilt3.jpg",
            alt: "",
            isWide: false
          }
        ],
        videos: [ // resizable
          {
            src: "",
            title: ""
          }
        ]
      }
    ]
  },
  // ========== adi-cd ==========
  {
    name: "ADI CD",
    path: "adi-cd",
    meta: {
      title: "ADI CD — Обладнання — ADISA",
      description: "description",
      keywords: "ADISA"
    },
    description: "Конденсаційний газовий котел з нержавіючої сталі ККД 108%",
    features: [ // resizable
      "・Виняткова ефективність та енергозбереження.",
      "・EC сертифікат, 4 зірки ефективності, сезонна ефективність 108% ККД.",
      "・Потужність від 70.5 до 892.3кВт.",
      "・Модуляція горіння починаючи з 23% потужності, вбудований PID контролер.",
      "・Змінна швидкість вентилятора, газо-повітряне преміксне змішання Вентурі, модуляційний газовий клапан.",
      "・Теплообмінник котла виготовлений із нержавіючої сталі. Відмінна стійкість до корозії та високої температури.",
      "・Немає ліміту по температурі зворотної води.",
      "・Мінімальний рівень шуму, від 48.3 dB, вбудовані звукопоглинаючі панелі.",
      "・Низькі викиди: клас NOx = 5, NOx <10ppm, CO близько 47ppm.",
      "・Вогнетривкий сплав сітки преміксного пальника.",
      "・Компактні розміри та низька вага.",
      "・Мінімальне споживання електроенергії, від 5Вт.",
      "・Можливе дистанційне керування."
    ],
    docsIntro: "Посилання та документи:",
    docs: [ // resizable
      {
        type: "link",
        text: "Інструкція ADISA ADI CD.",
        link: "/resources/ADI-CD-Rus.pdf"
      },
      {
        type: "link",
        text: "Сертифікат відповідності.",
        link: "/resources/UA024C0555-20.pdf"
      },
      {
        type: "link",
        text: "Сертифікат експертизи типу (до 400кВт).",
        link: "/resources/UATR012C0553-20.pdf"
      },
      {
        type: "link",
        text: "Сертифікат експертизи типу (понад 400кВт).",
        link: "/resources/UATR012C0554-20.pdf"
      },
      {
        type: "link",
        text: "Дозвіл ADISA.",
        link: "/resources/ADISA-dozvil.pdf"
      }
    ],
    table: [ // resizable
      [
        "ADI CD", "Од.", "70", "85", "105", "120", "175", "200", "250", "325", "375", "450", "550", "650", "750", "850", "950"
      ],
      [
        "Макс. потужність при 80/60ºC", "кВт", "70.5", "85.0", "104.0", "120.0", "161.8", "197.5", "241.0", "294.0", "354.0", "440.0", "530.0", "598.0", "675.0", "792.7", "892.3"
      ],
      [
        "Мін. потужність при 50/30ºC", "кВт", "21.8", "26.3", "26.1", "30.2", "40.6", "48.9", "60.1", "82.8", "95.7", "109.0", "230.9", "259.4", "292.4", "338.2", "380.9"
      ],
      [
        "Макс. витрата газу", "м3/г", "6.5", "8.2", "10.0", "11.5", "15.4", "18.8", "22.9", "27.9", "33.6", "41.7", "50.8", "57.5", "65.1", "76.1", "85.9"
      ],
      [
        "Вага без води", "кг", "110", "116", "120", "135", "138", "330", "350", "440", "445", "460", "460", "480", "485", "485", "545"
      ],
      [
        "Об'єм води", "л", "30", "33", "34", "34", "35", "86", "90", "112", "118", "118", "118", "120", "120", "120", "164"
      ],
      [
        "Макс. електроспоживання", "Вт", "236.5", "239.9", "254.9", "245.0", "249.9", "260.7", "274.1", "308.9", "435.0", "767.8", "627.4", "830.0", "1139.0", "1600.0", "1800.0"
      ],
      [
        "Живдення", "В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "380В", "380В"
      ],
      [
        "Ширина A", "мм", "350", "350", "350", "450", "450", "660", "660", "810", "810", "810", "1040", "1040", "1040", "1040", "1040"
      ],
      [
        "Глубина B", "мм", "595", "615", "635", "635", "635", "940", "940", "940", "940", "940", "940", "940", "940", "1083", "1083"
      ],
      [
        "Висота без опор H", "мм", "1110", "1110", "1110", "1110", "1110", "1583", "1583", "1583", "1583", "1583", "1628", "1628", "1628", "1628", "1628"
      ],
      [
        "Діаметр димоходу", "мм", "150", "150", "150", "150", "150", "180", "180", "250", "250", "250", "350", "350", "350", "350", "350"
      ]
    ],
    preview: [ // resizable
      {
        images: [ // resizable
          {
            description: "",
            path: "/img/adicd1.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/adicd2.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/adicd3.jpg",
            alt: "",
            isWide: false
          }
        ],
        videos: [ // resizable
          {
            src: "",
            title: ""
          }
        ]
      }
    ]
  },
  // ========== adi-mega ==========
  {
    name: "ADI MEGA",
    path: "adi-mega",
    meta: {
      title: "ADI MEGA — Обладнання — ADISA",
      description: "description",
      keywords: "ADISA"
    },
    description: "Конденсаційний модульний газовий котел з нержавіючої сталі ККД 108%",
    features: [ // resizable
      "・Виняткова ефективність та енергозбереження.",
      "・EC сертифікат, 4 зірки ефективності, сезонна ефективність 108% ККД.",
      "・Потужність від 1196 до 1784.7кВт.",
      "・Модуль складається з двох незалежних котлів. Кожен котел поставляється зі своїм паспортом. Не потрапляє під обмеження потужності котлів дахової котельні в 1250кВт.",
      "・Укомплектований каскадною і погодозалежною автоматикою.",
      "・Модуляція горіння починаючи з 23% потужності, вбудований PID контролер.",
      "・Змінна швидкість вентилятора, газо-повітряне преміксне змішання Вентурі, модуляційний газовий клапан.",
      "・Теплообмінник котла виготовлений із нержавіючої сталі. Відмінна стійкість до корозії та високої температури.",
      "・Немає ліміту по температурі зворотної води.",
      "・Мінімальний рівень шуму, від 48.3 dB, вбудовані звукопоглинаючі панелі.",
      "・Низькі викиди: клас NOx = 5, NOx <10ppm, CO близько 47ppm.",
      "・Вогнетривкий сплав сітки преміксного пальника.",
      "・Компактні розміри та низька вага.",
      "・Мінімальне споживання електроенергії, від 82.5Вт.",
      "・Можливе дистанційне керування."
    ],
    docsIntro: "Посилання та документи:",
    docs: [ // resizable
      {
        type: "link",
        text: "Інструкція ADISA ADI MEGA.",
        link: "/resources/ADI-MEGA-CD-UKR.pdf"
      },
      {
        type: "link",
        text: "Інструкція із збирання ADISA ADI MEGA.",
        link: "/resources/ADI-MEGA-assembly.pdf"
      },
      {
        type: "link",
        text: "Сертифікат відповідності.",
        link: "/resources/UA024C0555-20.pdf"
      },
      {
        type: "link",
        text: "Сертифікат експертизи типу (до 400кВт).",
        link: "/resources/UATR012C0553-20.pdf"
      },
      {
        type: "link",
        text: "Сертифікат експертизи типу (понад 400кВт).",
        link: "/resources/UATR012C0554-20.pdf"
      },
      {
        type: "link",
        text: "Дозвіл ADISA.",
        link: "/resources/ADISA-dozvil.pdf"
      }
    ],
    table: [ // resizable
      [
        "ADI MEGA", "Од.", "1200", "1600", "1800"
      ],
      [
        "Модуль складається з котлів", " ", "ADI CD 650 2шт.", "ADI CD 850 2шт.", "ADI CD 950 2шт."
      ],
      [
        "Макс. потужність при 80/60ºC", "кВт", "1196.0", "1585.5", "1784,7"
      ],
      [
        "Мін. потужність при 50/30ºC", "кВт", "256.4", "341.5", "380.9"
      ],
      [
        "Макс. витрата газу", "м3/г", "114.9", "152.2", "171.9"
      ],
      [
        "Вага без води", "кг", "970", "1090", "1090"
      ],
      [
        "Об'єм води", "л", "240", "328", "328"
      ],
      [
        "Максимальне електроспоживання", "Вт", "1660", "3300", "3600"
      ],
      [
        "Живлення", "В", "230В", "380В", "380В"
      ],
      [
        "Ширина A", "мм", "2004", "2008", "2008"
      ],
      [
        "Глубина B", "мм", "940", "1083", "1083"
      ],
      [
        "Висота без опор H", "мм", "1720", "1720", "1720"
      ],
      [
        "Діаметр димоходу", "мм", "2х350", "2х350", "2х350"
      ]
    ],
    preview: [ // resizable
      {
        images: [ // resizable
          {
            description: "",
            path: "/img/adimega1.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/adimega2.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/adimega3.jpg",
            alt: "",
            isWide: false
          }
        ],
        videos: [ // resizable
          {
            src: 'https://www.youtube.com/embed/DxukPBGlgb8',
            title: 'Инструкция по сборке модуля ADISA ADI MEGA'
          }
        ]
      }
    ]
  },
  // ========== condensa-pro ==========
  {
    name: "Condexa PRO",
    path: "condexa-pro",
    meta: {
      title: "Condexa PRO — Обладнання — ADISA",
      description: "description",
      keywords: "ADISA"
    },
    description: "Настінний конденсаційний газовий котел ККД 108%",
    features: [ // resizable
      "Condexa PRO - це нова пропозиція від ADISA, що представляє собою модульну настінну конденсаційну систему. Вона може мати відкриту або закриту камеру зпалювання, складатися з одиночного котлоагрегату або працювати в каскаді загальною потужністю 1120 кВт.<br/><br/>",
      "・Лінійка включає 8 моделей, представлених котловими модулями від 35 до 131 кВт.",
      "・Кожен котел оснащений інноваційним спіральним теплообмінником запатентованої геометричної форми. Він складається із концентричних гладких труб з нержавіючої сталі п'ятикутного перетину зсередини і круглого зовні, збільшують теплообмінну поверхню і забезпечують максимальну стійкість до корозії.",
      "・У моделі з приставкою P встановлено насос з частотним регулюванням. Базова електроніка управляє погодозалежною характеристикою, каскадною роботою котлових модулів, функцією ведучого / ведомого пристрою, автоматичним перемиканням режимів «Літо» / «Зима», а також прямою зоною та бойлером ГВП.",
      "・Вхід 0-10 В або протокол Modbus дозволяють додати дистанційне керування.",
      "・Оптимальне керування процесом горіння та високий коефіцієнт модуляції - дозволяють отримати високий ККД і низькі викиди забруднюючих речовин (клас 6 відповідно до нормативу UNI EN 297).",
      "・Безперервний режим роботи забезпечується модульним виконанням: навіть несправність одного з котлоагрегатів не впливає на загальне функціонування системи.",
      "・Автоматична зміна послідовності запалювання модулів.",
      "・Функція захисту від замерзання і заклинювання гарантують роботу за будь-яких погодних умов.",
      "・Можливість використання в якості аксесуарів (димоходи, сливи, підводи та ін. елементи обв'язки) Українських виробників.",
      "・Широкий асортимент приладдя допоможе спростити і прискорити установку, а також зробити комплектною каскадну систему."
    ],
    docsIntro: "Посилання та документи:",
    docs: [ // resizable
      {
        type: "link",
        text: "Інструкція ADISA Condexa PRO.",
        link: "/resources/ADI-Condexa-PRO-Rus.pdf"
      },
      {
        type: "link",
        text: "Каскад ADISA Condexa PRO.",
        link: "/resources/ADI-Condexa-PRO-Cascade_Rus.pdf"
      }
    ],
    table: [ // resizable
      [
        "Condexa PRO", "Од.", "35P", "50P", "57P", "70P", "90", "100", "115", "135"
      ],
      [
        "Тип камери зпалювання", " ", "відкрита/закрита", "відкрита/закрита", "відкрита/закрита", "відкрита/закрита", "відкрита/закрита", "відкрита/закрита", "відкрита/закрита", "відкрита/закрита"
      ],
      [
        "Спосіб створення тяги", " ", "димохід/турбина", "димохід/турбина", "димохід/турбина", "димохід/турбина", "димохід/турбина", "димохід/турбина", "димохід/турбина", "димохід/турбина"
      ],
      [
        "Максимальна теплова потужність", "кВт", "38.0", "48.8", "61.9", "73.9", "97.4", "105.1", "121.1", "142.1"
      ],
      [
        "Мінімальна теплова потужність", "кВт", "9.0", "9.0", "14.0", "14.0", "19.4", "19.4", "22.4", "26.2"
      ],
      [
        "Наявність вбудованого насоса", " ", "Є", "Є", "Є", "Є", "Немає", "Немає", "Немає", "Немає"
      ],
      [
        "ККД", "%", "109.5", "109.2", "109.2", "108.8", "108.9", "108.8", "108.8", "108.9"
      ],
      [
        "Діаметр димоходу (внутрішній / зовнішній)", "мм", "80 чи 60/100", "80 чи 60/100", "80 чи 60/100", "80 чи 60/100", "110 чи 110/160", "110 чи 110/160", "110 чи 110/160", "110 чи 110/160"
      ],
      [
        "Габаритні розміри HxLxP", "мм", "1000х600х435", "1000х600х435", "1000х600х435", "1000х600х435", "1000х600х435", "1000х600х435", "1170х600х435", "1170х600х435"
      ],
      [
        "Вага", "кг", "58", "58", "64", "64", "69", "69", "84", "90"
      ]
    ],
    preview: [ // resizable
      {
        images: [ // resizable
          {
            description: "",
            path: "/img/condexaBoiler.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/condexaDraw.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "Приклад каскаду з котлів Condexa Pro",
            path: "/img/condexaPro.jpg",
            alt: "",
            isWide: true
          }
        ],
        videos: [ // resizable
          {
            src: "",
            title: ""
          }
        ]
      }
    ]
  }
  // ========== Add+ ==========
];
