export default {
  meta: {
    title: "Документация — ADISA",
    description: "description",
    keywords: "ADISA"
  },
  title: "Сертификаты и документация",
  info: [ // resizable
    // ========== Инструкции на котлы========== Типы файлов: link и doc
    {
      caption: "Инструкции на котлы",
      list: [ // resizable
        {
          type: "link",
          text: "Инструкция ADISA ADI LT.",
          link: "/resources/ADI-LT-Rus.pdf"
        },
        {
          type: "link",
          text: "Инструкция ADISA ADI CD.",
          link: "/resources/ADI-CD-Rus.pdf"
        },
        {
          type: "link",
          text: "Инструкция ADISA ADI MEGA.",
          link: "/resources/ADI-MEGA-CD-Rus.pdf"
        },
        {
          type: "link",
          text: "Инструкция по сборке ADISA ADI MEGA.",
          link: "/resources/ADI-MEGA-assembly.pdf"
        },
        {
          type: "link",
          text: "Инструкция ADISA Condexa PRO.",
          link: "/resources/ADI-Condexa-PRO-Rus.pdf"
        },
        {
          type: "link",
          text: "Каскад ADISA Condexa PRO.",
          link: "/resources/ADI-Condexa-PRO-Cascade_Rus.pdf"
        }
      ],
    },
    // ========== Сертификаты и разрешения ==========
    {
      caption: "Сертификаты и разрешения",
      list: [
        {
          type: "link",
          text: "Сертификат соответствия.",
          link: "/resources/UA024C0555-20.pdf"
        },
        {
          type: "link",
          text: "Сертификат экспертизы типа (до 400кВт).",
          link: "/resources/UATR012C0553-20.pdf"
        },
        {
          type: "link",
          text: "Сертификат экспертизы типа (более 400кВт).",
          link: "/resources/UATR012C0554-20.pdf"
        },
        {
          type: "link",
          text: "Разрешение ADISA.",
          link: "/resources/ADISA-dozvil.pdf"
        }
      ]
    },
    // ========== Сервисному инженеру ==========
    {
      caption: "Сервисному инженеру",
      list: [
        {
          type: "link",
          text: "Обучение эксплуатации котлов ADISA.",
          link: "/resources/ADISA_education.pdf"
        },
        {
          type: "link",
          text: "Полная инструкция на контроллер Siemens LMS.",
          link: "/resources/LMS-Rus.pdf"
        },
        {
          type: "link",
          text: "Знакомство с контроллером Siemens LMS. Быстрый ввод в эксплуатацию.",
          link: "/resources/LMS-fast-start.pdf"
        },
        {
          type: "link",
          text: "Просмотр истории ошибок на новом дисплее.",
          link: "/resources/History-error.pdf"
        },
        {
          type: "link",
          text: "Сброс ошибок на новом дисплее.",
          link: "/resources/Reset-Errors.pdf"
        },
        {
          type: "link",
          text: "Взрывная схема котлов ADISA ADI 70-105.",
          link: "/resources/shema_ADI-70-105.pdf"
        },
        {
          type: "link",
          text: "Взрывная схема котлов ADISA ADI 130-200.",
          link: "/resources/shema_ADI-130-200.pdf"
        },
        {
          type: "link",
          text: "Взрывная схема котлов ADISA ADI 250-275.",
          link: "/resources/shema_ADI-250-275.pdf"
        },
        {
          type: "link",
          text: "Взрывная схема котлов ADISA ADI 325-475.",
          link: "/resources/shema_ADI-325-475.pdf"
        },
        {
          type: "link",
          text: "Взрывная схема котлов ADISA ADI 550-750.",
          link: "/resources/shema_ADI-550-750.pdf"
        },
        {
          type: "link",
          text: "Взрывная схема котлов ADISA ADI 850-950.",
          link: "/resources/shema_ADI-850-950.pdf"
        }
      ]
    },
    // ========== Проектировщику ==========
    {
      caption: "Проектировщику",
      list: [
        {
          type: "doc",
          text: "REVIT BIM модели ADISA ADI CD.",
          link: "/resources/ADISA_ADI-CD_BIM_engl.zip"
        },
        {
          type: "link",
          text: "Расход газа приведенный к каллорийности 8000ккал/м3.",
          link: "/resources/rashod-gaza.jpg"
        },
        {
          type: "link",
          text: "Площадь поверхности теплообмена котлов ADISA.",
          link: "/resources/poverhnost-teploobmena.pdf"
        },
        {
          type: "link",
          text: "Уровень шума котлов ADISA.",
          link: "/resources/noise-level-adisa.pdf"
        },
        {
          type: "link",
          text: "Пример котельной 478кВт.",
          link: "/resources/proekt-478kwt.pdf"
        },
        {
          type: "link",
          text: "Пример котельной 1090кВт.",
          link: "/resources/proekt-1090kwt.pdf"
        },
        {
          type: "link",
          text: "Пример котельной 1.3МВт.",
          link: "/resources/proekt-1.3mwt.pdf"
        },
        {
          type: "link",
          text: "Пример котельной 3.2МВт.",
          link: "/resources/proekt-3.2mwt.pdf"
        },
        {
          type: "link",
          text: "Пример котельной 5МВт.",
          link: "/resources/proekt-5mwt.pdf"
        }
      ]
    }
    // ========== Add+ ==========
  ]
}
