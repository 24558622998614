import header from './header';
import footer from './footer';
import calculate from './calculate';
import home from './home';
import equipment from './equipment';
import info from './info';
import contacts from './contacts';

export default {
  header,
  footer,
  calculate,
  home,
  equipment,
  info,
  contacts
};
