const locales = [
  {
    uri: 'ua',
    text: 'Укр'
  },
  {
    uri: 'ru',
    text: 'Рус'
  }
];

export default locales;
