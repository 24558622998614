export default {
  calculateBtn: "Просчитать котельную",
  title: "Просчитать котельную",
  description: "Введите е-мейл или номер телефона, и мы свяжемся с вами удобным для вас способом",
  form: {
    labels: { 
      name: "Введите ваше имя",
      contact: "Введите ваш e-mail или номер телефона",
      message: "Оставьте сообщение"
    },
    submitBtn: "Просчитать котельную",
    errors: {
      title: "Пожалуйста, заполните все поля: ",
      fields: {
        name: {
          cantBeBlank: "Введите имя или название организации."
        },
        contact: {
          cantBeBlank: "Введите е-мейл или номер телефона.",
          invalidInput: "E-mail или номер телефона введён не верно."
        },
        captcha: {
          cantBeBlank: "Пройдите проверку reCAPTCHA."
        }
      }
    },
    success: {
      message: "Ваш запрос успешно отправлен. Мы свяжемся с вами в ближайшее время!"
    }
  }
};
