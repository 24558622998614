export default {
  title: "ADISA - більше 50-ти років на ринку Європи та США",
  leftDescriptions: [ // +b resizable
    "ADISA Group була заснована в 1961 П'єром Марті, її чинним президентом.",
    "- ADISA Calefaccion (опалювальне обладнання): виробництво високоефективних рішень для опалення та виробництва гарячої води, середньої і великої потужності, таких як котли та модульні котельні;",
    "- ADISA Antilegionella гарантує постійне усунення бактерій в системі ГВП."
  ],
  rightDescriptions: [ // +b resizable
    "Домігшись визнання на європейському та американському ринках, компанія зацікавилася ринком країн Східної Європи. У рік свого 50-річного ювілею ADISA Group вийшла на ринок України. Розвиток компанії базується на політиці постійних інвестицій в дослідження, на ділових взаєминах з партнерами, а також на клієнті і його потребах."
  ]
};
