import React, { useRef, useImperativeHandle } from 'react';
import cx from 'classnames';

import Title from 'components/common/Title/Title';
import styles from './NoiseLvl.module.scss';
import boiler from './img/boiler.png';
import boilerSmall from './img/boiler-sm.jpg';
import washingMachine from './img/washing-machine.svg';
import clock from './img/clock.svg';
import vacuum from './img/vacuum.svg';
import feature1 from './img/feature1.jpg';
import feature2 from './img/feature2.jpg';
import feature3 from './img/feature3.jpg';
import parseStrWithBoldElems from 'utils/parseStrWithBoldElems';

const NoiseLvl = React.forwardRef(({ data }, ref) => {
  const {
    title, description, advantagesListTitle, advantagesList, boilerAlt, featureName1, featureAlt1, featureName2,
    featureAlt2, featureName3, featureAlt3
  } = data;
  const renderAdvantagesList = () =>
    advantagesList.map((curr, index) => <li key={index}>{ parseStrWithBoldElems(curr) }</li>)

  const noiseLvlRightChartRef = useRef();
  const noiseLvlExampleRef = useRef();
  useImperativeHandle(ref, () => ({
    get noiseLvlRightChart() {
      return noiseLvlRightChartRef.current
    },
    get noiseLvlExample() {
      return noiseLvlExampleRef.current
    }
  }));

  return (
    <div className={styles.container}>
      <Title title={title} number="3" />
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.infoText}>
            <p className={styles.text}>{ parseStrWithBoldElems(description) }</p>
            <div className={styles.listTitle}>{ advantagesListTitle }</div>
            <ul className={styles.list}>
              { renderAdvantagesList() }
            </ul>
          </div>
          <div className={styles.chartWrap} ref={noiseLvlRightChartRef}>
            <svg viewBox="0 0 487 414" className={styles.chart} fill="none" xmlns="http://www.w3.org/2000/svg">
              <line opacity="0.5" x1="38" y1="63.5" x2="438" y2="63.5" stroke="#0077E4"/>
              <line opacity="0.5" x1="38" y1="409.5" x2="487" y2="409.5" stroke="#0077E4"/>
              <line opacity="0.5" x1="486.688" y1="409.39" x2="481.688" y2="405.39" stroke="#0077E4"/>
              <line opacity="0.5" y1="-0.5" x2="6.40312" y2="-0.5" transform="matrix(-0.780869 0.624695 0.624695 0.780869 487 410)" stroke="#0077E4"/>
              <line opacity="0.5" x1="38" y1="359.5" x2="438" y2="359.5" stroke="#0077E4"/>
              <line opacity="0.5" x1="38" y1="309.5" x2="438" y2="309.5" stroke="#0077E4"/>
              <line opacity="0.5" x1="38" y1="259.5" x2="438" y2="259.5" stroke="#0077E4"/>
              <line opacity="0.5" x1="38" y1="210.5" x2="438" y2="210.5" stroke="#0077E4"/>
              <line opacity="0.5" x1="38" y1="161.5" x2="438" y2="161.5" stroke="#0077E4"/>
              <line opacity="0.5" x1="38" y1="112.5" x2="438" y2="112.5" stroke="#0077E4"/>
              <g transform="translate(95 244)">
                <image className={styles.chartPic1} href={clock} />
              </g>
              <g transform="translate(200 130)">
                <image className={styles.chartPic2} href={boilerSmall} width="26" height="45" />
              </g>
              <g transform="translate(298 72)">
                <image className={styles.chartPic3} href={washingMachine} />
              </g>
              <g transform="translate(396 18)">
                <image className={styles.chartPic4} href={vacuum} />
              </g>
              <rect className={styles.chartRect1} x="88" y="284" width="51" height="126" fill="#0077E4"/>
              <rect className={styles.chartRect2} x="188" y="183" width="51" height="227" fill="#922033"/>
              <rect className={styles.chartRect3} x="288" y="112" width="51" height="298" fill="#0077E4"/>
              <rect className={styles.chartRect4} x="388" y="63" width="50" height="347" fill="#0077E4"/>
              <line opacity="0.5" x1="38.5" y1="27" x2="38.5" y2="410" stroke="#0077E4"/>
              <path d="M38.8281 19.9609H37.5703V17H31.5859V19.9609H30.3359V15.8828H31.0078C31.7057 14.9349 32.2526 13.8229 32.6484 12.5469C33.0443 11.2708 33.25 9.90104 33.2656 8.4375H37.6016V15.8828H38.8281V19.9609ZM36.3516 15.8828V9.46094H34.4297C34.362 10.6172 34.138 11.7891 33.7578 12.9766C33.3828 14.1589 32.9141 15.1276 32.3516 15.8828H36.3516ZM48.1484 13.6719C48.1484 14.8073 47.8099 15.6458 47.1328 16.1875C46.4609 16.7292 45.4453 17 44.0859 17H40.7422V5.57812H47.4766V6.75781H42.0703V10.4531H43.8438C44.849 10.4531 45.6667 10.5703 46.2969 10.8047C46.9271 11.0339 47.3932 11.375 47.6953 11.8281C47.9974 12.2812 48.1484 12.8958 48.1484 13.6719ZM42.0703 15.8516H43.9375C44.9271 15.8516 45.6458 15.6823 46.0938 15.3438C46.5469 15.0052 46.7734 14.4479 46.7734 13.6719C46.7734 12.9323 46.5443 12.4062 46.0859 12.0938C45.6276 11.7812 44.8281 11.625 43.6875 11.625H42.0703V15.8516Z" fill="#0077E4"/>
              <path d="M16.2734 366H15.0078V357.859C15.0078 357.182 15.0286 356.542 15.0703 355.938C14.9609 356.047 14.8385 356.161 14.7031 356.281C14.5677 356.401 13.9479 356.909 12.8438 357.805L12.1562 356.914L15.1797 354.578H16.2734V366ZM28.1953 360.273C28.1953 362.247 27.8828 363.721 27.2578 364.695C26.638 365.669 25.6875 366.156 24.4062 366.156C23.1771 366.156 22.2422 365.659 21.6016 364.664C20.9609 363.664 20.6406 362.201 20.6406 360.273C20.6406 358.284 20.9505 356.807 21.5703 355.844C22.1901 354.88 23.1354 354.398 24.4062 354.398C25.6458 354.398 26.5859 354.901 27.2266 355.906C27.8724 356.911 28.1953 358.367 28.1953 360.273ZM21.9531 360.273C21.9531 361.935 22.1484 363.146 22.5391 363.906C22.9297 364.661 23.5521 365.039 24.4062 365.039C25.2708 365.039 25.8958 364.656 26.2812 363.891C26.6719 363.12 26.8672 361.914 26.8672 360.273C26.8672 358.633 26.6719 357.432 26.2812 356.672C25.8958 355.906 25.2708 355.523 24.4062 355.523C23.5521 355.523 22.9297 355.901 22.5391 356.656C22.1484 357.406 21.9531 358.612 21.9531 360.273Z" fill="#0077E4"/>
              <path d="M18.9766 317H11.4688V315.883L14.4766 312.859C15.3932 311.932 15.9974 311.271 16.2891 310.875C16.5807 310.479 16.7995 310.094 16.9453 309.719C17.0911 309.344 17.1641 308.94 17.1641 308.508C17.1641 307.898 16.9792 307.417 16.6094 307.062C16.2396 306.703 15.7266 306.523 15.0703 306.523C14.5964 306.523 14.1458 306.602 13.7188 306.758C13.2969 306.914 12.8255 307.198 12.3047 307.609L11.6172 306.727C12.6693 305.852 13.8151 305.414 15.0547 305.414C16.1276 305.414 16.9688 305.69 17.5781 306.242C18.1875 306.789 18.4922 307.526 18.4922 308.453C18.4922 309.177 18.2891 309.893 17.8828 310.602C17.4766 311.31 16.7161 312.206 15.6016 313.289L13.1016 315.734V315.797H18.9766V317ZM28.1953 311.273C28.1953 313.247 27.8828 314.721 27.2578 315.695C26.638 316.669 25.6875 317.156 24.4062 317.156C23.1771 317.156 22.2422 316.659 21.6016 315.664C20.9609 314.664 20.6406 313.201 20.6406 311.273C20.6406 309.284 20.9505 307.807 21.5703 306.844C22.1901 305.88 23.1354 305.398 24.4062 305.398C25.6458 305.398 26.5859 305.901 27.2266 306.906C27.8724 307.911 28.1953 309.367 28.1953 311.273ZM21.9531 311.273C21.9531 312.935 22.1484 314.146 22.5391 314.906C22.9297 315.661 23.5521 316.039 24.4062 316.039C25.2708 316.039 25.8958 315.656 26.2812 314.891C26.6719 314.12 26.8672 312.914 26.8672 311.273C26.8672 309.633 26.6719 308.432 26.2812 307.672C25.8958 306.906 25.2708 306.523 24.4062 306.523C23.5521 306.523 22.9297 306.901 22.5391 307.656C22.1484 308.406 21.9531 309.612 21.9531 311.273Z" fill="#0077E4"/>
              <path d="M18.5469 258.266C18.5469 258.995 18.3411 259.591 17.9297 260.055C17.5234 260.518 16.9453 260.828 16.1953 260.984V261.047C17.112 261.161 17.7917 261.453 18.2344 261.922C18.6771 262.391 18.8984 263.005 18.8984 263.766C18.8984 264.854 18.5208 265.693 17.7656 266.281C17.0104 266.865 15.9375 267.156 14.5469 267.156C13.9427 267.156 13.388 267.109 12.8828 267.016C12.3828 266.927 11.8958 266.768 11.4219 266.539V265.305C11.9167 265.549 12.4427 265.737 13 265.867C13.5625 265.992 14.0938 266.055 14.5938 266.055C16.5677 266.055 17.5547 265.281 17.5547 263.734C17.5547 262.349 16.4661 261.656 14.2891 261.656H13.1641V260.539H14.3047C15.1953 260.539 15.901 260.344 16.4219 259.953C16.9427 259.557 17.2031 259.01 17.2031 258.312C17.2031 257.755 17.0104 257.318 16.625 257C16.2448 256.682 15.7266 256.523 15.0703 256.523C14.5703 256.523 14.099 256.591 13.6562 256.727C13.2135 256.862 12.7083 257.112 12.1406 257.477L11.4844 256.602C11.9531 256.232 12.4922 255.943 13.1016 255.734C13.7161 255.521 14.362 255.414 15.0391 255.414C16.1484 255.414 17.0104 255.669 17.625 256.18C18.2396 256.685 18.5469 257.38 18.5469 258.266ZM28.1953 261.273C28.1953 263.247 27.8828 264.721 27.2578 265.695C26.638 266.669 25.6875 267.156 24.4062 267.156C23.1771 267.156 22.2422 266.659 21.6016 265.664C20.9609 264.664 20.6406 263.201 20.6406 261.273C20.6406 259.284 20.9505 257.807 21.5703 256.844C22.1901 255.88 23.1354 255.398 24.4062 255.398C25.6458 255.398 26.5859 255.901 27.2266 256.906C27.8724 257.911 28.1953 259.367 28.1953 261.273ZM21.9531 261.273C21.9531 262.935 22.1484 264.146 22.5391 264.906C22.9297 265.661 23.5521 266.039 24.4062 266.039C25.2708 266.039 25.8958 265.656 26.2812 264.891C26.6719 264.12 26.8672 262.914 26.8672 261.273C26.8672 259.633 26.6719 258.432 26.2812 257.672C25.8958 256.906 25.2708 256.523 24.4062 256.523C23.5521 256.523 22.9297 256.901 22.5391 257.656C22.1484 258.406 21.9531 259.612 21.9531 261.273Z" fill="#0077E4"/>
              <path d="M19.5156 214.375H17.8203V217H16.5781V214.375H11.0234V213.242L16.4453 205.516H17.8203V213.195H19.5156V214.375ZM16.5781 213.195V209.398C16.5781 208.654 16.6042 207.812 16.6562 206.875H16.5938C16.3438 207.375 16.1094 207.789 15.8906 208.117L12.3203 213.195H16.5781ZM28.1953 211.273C28.1953 213.247 27.8828 214.721 27.2578 215.695C26.638 216.669 25.6875 217.156 24.4062 217.156C23.1771 217.156 22.2422 216.659 21.6016 215.664C20.9609 214.664 20.6406 213.201 20.6406 211.273C20.6406 209.284 20.9505 207.807 21.5703 206.844C22.1901 205.88 23.1354 205.398 24.4062 205.398C25.6458 205.398 26.5859 205.901 27.2266 206.906C27.8724 207.911 28.1953 209.367 28.1953 211.273ZM21.9531 211.273C21.9531 212.935 22.1484 214.146 22.5391 214.906C22.9297 215.661 23.5521 216.039 24.4062 216.039C25.2708 216.039 25.8958 215.656 26.2812 214.891C26.6719 214.12 26.8672 212.914 26.8672 211.273C26.8672 209.633 26.6719 208.432 26.2812 207.672C25.8958 206.906 25.2708 206.523 24.4062 206.523C23.5521 206.523 22.9297 206.901 22.5391 207.656C22.1484 208.406 21.9531 209.612 21.9531 211.273Z" fill="#0077E4"/>
              <path d="M15.0391 161.023C16.2422 161.023 17.1875 161.323 17.875 161.922C18.5677 162.516 18.9141 163.331 18.9141 164.367C18.9141 165.549 18.5365 166.477 17.7812 167.148C17.0312 167.82 15.9948 168.156 14.6719 168.156C13.3854 168.156 12.4036 167.951 11.7266 167.539V166.289C12.0911 166.523 12.5443 166.708 13.0859 166.844C13.6276 166.974 14.1615 167.039 14.6875 167.039C15.6042 167.039 16.3151 166.823 16.8203 166.391C17.3307 165.958 17.5859 165.333 17.5859 164.516C17.5859 162.922 16.6094 162.125 14.6562 162.125C14.1615 162.125 13.5 162.201 12.6719 162.352L12 161.922L12.4297 156.578H18.1094V157.773H13.5391L13.25 161.203C13.849 161.083 14.4453 161.023 15.0391 161.023ZM28.1953 162.273C28.1953 164.247 27.8828 165.721 27.2578 166.695C26.638 167.669 25.6875 168.156 24.4062 168.156C23.1771 168.156 22.2422 167.659 21.6016 166.664C20.9609 165.664 20.6406 164.201 20.6406 162.273C20.6406 160.284 20.9505 158.807 21.5703 157.844C22.1901 156.88 23.1354 156.398 24.4062 156.398C25.6458 156.398 26.5859 156.901 27.2266 157.906C27.8724 158.911 28.1953 160.367 28.1953 162.273ZM21.9531 162.273C21.9531 163.935 22.1484 165.146 22.5391 165.906C22.9297 166.661 23.5521 167.039 24.4062 167.039C25.2708 167.039 25.8958 166.656 26.2812 165.891C26.6719 165.12 26.8672 163.914 26.8672 162.273C26.8672 160.633 26.6719 159.432 26.2812 158.672C25.8958 157.906 25.2708 157.523 24.4062 157.523C23.5521 157.523 22.9297 157.901 22.5391 158.656C22.1484 159.406 21.9531 160.612 21.9531 162.273Z" fill="#0077E4"/>
              <path d="M11.6016 114.117C11.6016 111.872 12.0365 110.195 12.9062 109.086C13.7812 107.971 15.0729 107.414 16.7812 107.414C17.3698 107.414 17.8333 107.464 18.1719 107.562V108.68C17.7708 108.549 17.3125 108.484 16.7969 108.484C15.5729 108.484 14.638 108.867 13.9922 109.633C13.3464 110.393 12.9922 111.591 12.9297 113.227H13.0234C13.5964 112.331 14.5026 111.883 15.7422 111.883C16.7682 111.883 17.5755 112.193 18.1641 112.812C18.7578 113.432 19.0547 114.273 19.0547 115.336C19.0547 116.523 18.7292 117.458 18.0781 118.141C17.4323 118.818 16.5573 119.156 15.4531 119.156C14.2708 119.156 13.3333 118.714 12.6406 117.828C11.9479 116.938 11.6016 115.701 11.6016 114.117ZM15.4375 118.055C16.1771 118.055 16.75 117.823 17.1562 117.359C17.5677 116.891 17.7734 116.216 17.7734 115.336C17.7734 114.581 17.5833 113.987 17.2031 113.555C16.8229 113.122 16.2552 112.906 15.5 112.906C15.0312 112.906 14.6016 113.003 14.2109 113.195C13.8203 113.388 13.5078 113.654 13.2734 113.992C13.0443 114.331 12.9297 114.682 12.9297 115.047C12.9297 115.583 13.0339 116.083 13.2422 116.547C13.4505 117.01 13.7448 117.378 14.125 117.648C14.5104 117.919 14.9479 118.055 15.4375 118.055ZM28.1953 113.273C28.1953 115.247 27.8828 116.721 27.2578 117.695C26.638 118.669 25.6875 119.156 24.4062 119.156C23.1771 119.156 22.2422 118.659 21.6016 117.664C20.9609 116.664 20.6406 115.201 20.6406 113.273C20.6406 111.284 20.9505 109.807 21.5703 108.844C22.1901 107.88 23.1354 107.398 24.4062 107.398C25.6458 107.398 26.5859 107.901 27.2266 108.906C27.8724 109.911 28.1953 111.367 28.1953 113.273ZM21.9531 113.273C21.9531 114.935 22.1484 116.146 22.5391 116.906C22.9297 117.661 23.5521 118.039 24.4062 118.039C25.2708 118.039 25.8958 117.656 26.2812 116.891C26.6719 116.12 26.8672 114.914 26.8672 113.273C26.8672 111.633 26.6719 110.432 26.2812 109.672C25.8958 108.906 25.2708 108.523 24.4062 108.523C23.5521 108.523 22.9297 108.901 22.5391 109.656C22.1484 110.406 21.9531 111.612 21.9531 113.273Z" fill="#0077E4"/>
              <path d="M12.9141 70L17.6484 59.7734H11.4219V58.5781H19.0234V59.6172L14.3516 70H12.9141ZM28.1953 64.2734C28.1953 66.2474 27.8828 67.7214 27.2578 68.6953C26.638 69.6693 25.6875 70.1562 24.4062 70.1562C23.1771 70.1562 22.2422 69.6589 21.6016 68.6641C20.9609 67.6641 20.6406 66.2005 20.6406 64.2734C20.6406 62.2839 20.9505 60.8073 21.5703 59.8438C22.1901 58.8802 23.1354 58.3984 24.4062 58.3984C25.6458 58.3984 26.5859 58.901 27.2266 59.9062C27.8724 60.9115 28.1953 62.3672 28.1953 64.2734ZM21.9531 64.2734C21.9531 65.9349 22.1484 67.1458 22.5391 67.9062C22.9297 68.6615 23.5521 69.0391 24.4062 69.0391C25.2708 69.0391 25.8958 68.6562 26.2812 67.8906C26.6719 67.1198 26.8672 65.9141 26.8672 64.2734C26.8672 62.6328 26.6719 61.4323 26.2812 60.6719C25.8958 59.9062 25.2708 59.5234 24.4062 59.5234C23.5521 59.5234 22.9297 59.901 22.5391 60.6562C22.1484 61.4062 21.9531 62.612 21.9531 64.2734Z" fill="#0077E4"/>
              <line opacity="0.5" x1="88.5" y1="63" x2="88.5" y2="410" stroke="#0077E4"/>
              <line opacity="0.5" x1="238.5" y1="63" x2="238.5" y2="410" stroke="#0077E4"/>
              <line opacity="0.5" x1="138.5" y1="63" x2="138.5" y2="410" stroke="#0077E4"/>
              <line opacity="0.5" x1="288.5" y1="63" x2="288.5" y2="410" stroke="#0077E4"/>
              <line opacity="0.5" x1="188.5" y1="63" x2="188.5" y2="410" stroke="#0077E4"/>
              <line opacity="0.5" x1="338.5" y1="63" x2="338.5" y2="410" stroke="#0077E4"/>
              <line opacity="0.5" x1="388.5" y1="63" x2="388.5" y2="410" stroke="#0077E4"/>
              <line opacity="0.5" x1="438.5" y1="63" x2="438.5" y2="410" stroke="#0077E4"/>
              <line opacity="0.5" x1="38.3904" y1="27.3123" x2="34.3904" y2="32.3123" stroke="#0077E4"/>
              <line opacity="0.5" y1="-0.5" x2="6.40312" y2="-0.5" transform="matrix(0.624695 0.780869 0.780869 -0.624695 39 27)" stroke="#0077E4"/>
            </svg>
          </div>
        </div>
        <div className={styles.example} ref={noiseLvlExampleRef}>
          <div className={styles.boiler}>
            <div className={styles.connector} />
            <img src={boiler} alt={boilerAlt} className={styles.boilerImg} />
          </div>
          <div className={styles.features}>
            <div className={styles.redLine} />
            <div className={cx(styles.feature, styles.feature1)}>
              <div className={styles.nameWrap}><div className={styles.name}>{ featureName1 }</div></div>
              <img src={feature1} alt={featureAlt1} className={styles.imageFeature} />
            </div>
            <div className={cx(styles.feature, styles.feature2)}>
            <div className={styles.nameWrap}><div className={styles.name}>{ featureName2 }</div></div>
              <img src={feature2} alt={featureAlt2} className={styles.imageFeature} />
            </div>
            <div className={cx(styles.feature, styles.feature3)}>
            <div className={styles.nameWrap3}><div className={styles.name}>{ featureName3 }</div></div>
              <img src={feature3} alt={featureAlt3} className={styles.imageFeature} />
            </div>
          </div>
        </div>
      </div> 
    </div>
  );
});

export default NoiseLvl;
