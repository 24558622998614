export default [ // resizable
  // ========== adi-lt ==========
  {
    name: "ADI LT",
    path: "adi-lt",
    meta: {
      title: "ADI LT — Оборудование — ADISA",
      description: "description",
      keywords: "ADISA"
    },
    description: "Конденсационный газовый котел из нержавеющей стали КПД 104%",
    features: [ // resizable
      "・Исключительная эффективность и энергосбережение.",
      "・EC сертификат, 3 звезды эффективности до 104% КПД.",
      "・Мощность от 104 до 905кВт.",
      "・Модуляция горения начиная с 23% мощности, встроенный PID контроллер.",
      "・Изменяемая скорость вентилятора, газо-воздушное премиксное смешение Вентури, модулирующий газовый клапан.",
      "・Теплообменник котла изготовлен из нержавеющей стали. Отличная устойчивость к коррозии и высокой температуре.",
      "・Нет лимита по температуре обратной воды.",
      "・Минимальный уровень шума, от 48.3 dB, встроенные звукопоглащающие панели.",
      "・Низкие выбросы: класс NOx=5, NOx<10ppm, CO около 47ppm.",
      "・Огнеупорный сплав сетки премиксной горелки.",
      "・Компактные размеры и низкий вес.",
      "・Минимальное потребление электроэнергии, от 14Вт.",
      "・Возможно удаленное управление."
    ],
    docsIntro: "Ссылки и документы:",
    docs: [ // resizable
      {
        type: "link",
        text: "Инструкция ADISA ADI LT.",
        link: "/resources/ADI-LT-Rus.pdf"
      },
      {
        type: "link",
        text: "Сертификат соответствия.",
        link: "/resources/UA024C0555-20.pdf"
      },
      {
        type: "link",
        text: "Сертификат экспертизы типа (до 400кВт). ",
        link: "/resources/UATR012C0553-20.pdf"
      },
      {
        type: "link",
        text: "Сертификат экспертизы типа (более 400кВт).",
        link: "/resources/UATR012C0554-20.pdf"
      },
      {
        type: "link",
        text: "Разрешение ADISA.",
        link: "/resources/ADISA-dozvil.pdf"
      }
    ],
    table: [ // resizable
      [
        "ADI LT", "Ед.", "105", "130", "150", "200", "250", "275", "325", "400", "475", "550", "650", "750", "850", "950"
      ],
      [
        "Макс. мощность при 80/60ºC", "кВт", "104.3", "131.3", "149.7", "190.6", "230.7", "262.0", "322.0", "380.0", "464.0", "545.0", "616.0", "695.0", "804.0", "905.0"
      ],
      [
        "Мин. мощность при 50/30ºC", "кВт", "34.4", "43.3", "49.3", "62.8", "76.0", "84.8", "104.9", "124.4", "151,8", "236.3", "266.6", "299.4", "345.3", "386.3"
      ],
              [
        "Макс. расход газа", "м3/ч", "10.2", "12.8", "14.6", "18.6", "22.5", "25.1", "31.0", "36.8", "44.9", "52.4", "59.3", "67.0", "77.1", "87,2"
      ],
      [
        "Вес без воды", "кг", "110", "112", "123", "139", "330", "350", "440", "445", "460", "480", "485", "485", "545", "545"
      ],
      [
        "Объем воды", "л", "30", "30", "33", "36", "76", "85", "99", "106", "118", "120", "120", "120", "164", "164"
      ],
      [
        "Макс. электропотребление", "Вт", "255", "268", "282", "256", "314", "259", "342", "445", "727", "668", "859", "1165", "1650", "1850"
      ],
      [
        "Питание", "В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "380В", "380В"
      ],
      [
        "Ширина A", "мм", "350", "450", "450", "450", "660", "660", "810", "810", "810", "1040", "1040", "1040", "1040", "1040"
      ],
      [
        "Глубина B", "мм", "595", "595", "615", "635", "940", "940", "940", "940", "940", "940", "940", "940", "1083", "1083"
      ],
      [
        "Высота без опор H", "мм", "1110", "1110", "1110", "1110", "1583", "1583", "1583", "1583", "1583", "1628", "1628", "1628", "1628", "1628"
      ],
      [
        "Диаметр дымохода", "мм", "150", "150", "150", "150", "175", "175", "250", "250", "250", "350", "350", "350", "350", "350"
      ]
    ],
    preview: [ // resizable
      {
        images: [ // resizable
          {
            description: "",
            path: "/img/adilt1.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/adilt2.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/adilt3.jpg",
            alt: "",
            isWide: false
          }
        ],
        videos: [ // resizable
          {
            src: "",
            title: ""
          }
        ]
      }
    ]
  },
  // ========== adi-cd ==========
  {
    name: "ADI CD",
    path: "adi-cd",
    meta: {
      title: "ADI CD — Оборудование — ADISA",
      description: "description",
      keywords: "ADISA"
    },
    description: "Конденсационный газовый котел из нержавеющей стали КПД 108%",
    features: [ // resizable
      "・Исключительная эффективность и энергосбережение.",
      "・EC сертификат, 4 звезды эффективности, сезонная эффективность 108% КПД.",
      "・Мощность от 70.5 до 892.3кВт.",
      "・Модуляция горения начиная с 23% мощности, встроенный PID контроллер.",
      "・Изменяемая скорость вентилятора, газо-воздушное премиксное смешение Вентури, модулирующий газовый клапан.",
      "・Теплообменник котла изготовлен из нержавеющей стали. Отличная устойчивость к коррозии и высокой температуре.",
      "・Нет лимита по температуре обратной воды.",
      "・Минимальный уровень шума, от 48.3 dB, встроенные звукопоглащающие панели.",
      "・Низкие выбросы: класс NOx=5, NOx<10ppm, CO около 47ppm.",
      "・Огнеупорный сплав сетки премиксной горелки.",
      "・Компактные размеры и низкий вес.",
      "・Минимальное потребление электроэнергии, от 5Вт.",
      "・Возможно удаленное управление."
    ],
    docsIntro: "Ссылки и документы:",
    docs: [ // resizable
      {
        type: "link",
        text: "Инструкция ADISA ADI CD.",
        link: "/resources/ADI-CD-Rus.pdf"
      },
      {
        type: "link",
        text: "Сертификат соответствия.",
        link: "/resources/UA024C0555-20.pdf"
      },
      {
        type: "link",
        text: "Сертификат экспертизы типа (до 400кВт). ",
        link: "/resources/UATR012C0553-20.pdf"
      },
      {
        type: "link",
        text: "Сертификат экспертизы типа (более 400кВт).",
        link: "/resources/UATR012C0554-20.pdf"
      },
      {
        type: "link",
        text: "Разрешение ADISA.",
        link: "/resources/ADISA-dozvil.pdf"
      }
    ],
    table: [ // resizable
      [
        "ADI CD", "Ед.", "70", "85", "105", "120", "175", "200", "250", "325", "375", "450", "550", "650", "750", "850", "950"
      ],
      [
        "Макс. мощность при 80/60ºC", "кВт", "70.5", "85.0", "104.0", "120.0", "161.8", "197.5", "241.0", "294.0", "354.0", "440.0", "530.0", "598.0", "675.0", "792.7", "892.3"
      ],
      [
        "Мин. мощность при 50/30ºC", "кВт", "21.8", "26.3", "26.1", "30.2", "40.6", "48.9", "60.1", "82.8", "95.7", "109.0", "230.9", "259.4", "292.4", "338.2", "380.9"
      ],
      [
        "Макс. расход газа", "м3/ч", "6.5", "8.2", "10.0", "11.5", "15.4", "18.8", "22.9", "27.9", "33.6", "41.7", "50.8", "57.5", "65.1", "76.1", "85.9"
      ],
      [
        "Вес без воды", "кг", "110", "116", "120", "135", "138", "330", "350", "440", "445", "460", "460", "480", "485", "485", "545"
      ],
      [
        "Объем воды", "л", "30", "33", "34", "34", "35", "86", "90", "112", "118", "118", "118", "120", "120", "120", "164"
      ],
      [
        "Макс. электропотребление", "Вт", "236.5", "239.9", "254.9", "245.0", "249.9", "260.7", "274.1", "308.9", "435.0", "767.8", "627.4", "830.0", "1139.0", "1600.0", "1800.0"
      ],
      [
        "Питание", "В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "230В", "380В", "380В"
      ],
      [
        "Ширина A", "мм", "350", "350", "350", "450", "450", "660", "660", "810", "810", "810", "1040", "1040", "1040", "1040", "1040"
      ],
      [
        "Глубина B", "мм", "595", "615", "635", "635", "635", "940", "940", "940", "940", "940", "940", "940", "940", "1083", "1083"
      ],
      [
        "Высота без опор H", "мм", "1110", "1110", "1110", "1110", "1110", "1583", "1583", "1583", "1583", "1583", "1628", "1628", "1628", "1628", "1628"
      ],
      [
        "Диаметр дымохода", "мм", "150", "150", "150", "150", "150", "180", "180", "250", "250", "250", "350", "350", "350", "350", "350"
      ]
    ],
    preview: [ // resizable
      {
        images: [ // resizable
          {
            description: "",
            path: "/img/adicd1.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/adicd2.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/adicd3.jpg",
            alt: "",
            isWide: false
          }
        ],
        videos: [ // resizable
          {
            src: "",
            title: ""
          }
        ]
      }
    ]
  },
  // ========== adi-mega ==========
  {
    name: "ADI MEGA",
    path: "adi-mega",
    meta: {
      title: "ADI MEGA — Оборудование — ADISA",
      description: "description",
      keywords: "ADISA"
    },
    description: "Конденсационный модульный газовый котел из нержавеющей стали КПД 108%",
    features: [ // resizable
      "・Исключительная эффективность и энергосбережение.",
      "・EC сертификат, 4 звезды эффективности, сезонная эффективность 108% КПД.",
      "・Мощность от 1196 до 1784.7кВт.",
      "・Модуль состоит из двух независимых котлов. Каждый котел поставляется со своим паспортом. Не попадает под ограничение мощности котлов крыщных котелен в 1250кВт.",
      "・Укомплектован каскадной и погодозависимой автоматикой.",
      "・Модуляция горения начиная с 23% мощности, встроенный PID контроллер.",
      "・Изменяемая скорость вентилятора, газо-воздушное премиксное смешение Вентури, модулирующий газовый клапан.",
      "・Теплообменник котла изготовлен из нержавеющей стали. Отличная устойчивость к коррозии и высокой температуре.",
      "・Нет лимита по температуре обратной воды.",
      "・Минимальный уровень шума, от 48.3 dB, встроенные звукопоглащающие панели.",
      "・Низкие выбросы: класс NOx=5, NOx<10ppm, CO около 47ppm.",
      "・Огнеупорный сплав сетки премиксной горелки.",
      "・Компактные размеры и низкий вес.",
      "・Минимальное потребление электроэнергии, от 82.5Вт.",
      "・Возможно удаленное управление."
    ],
    docsIntro: "Ссылки и документы:",
    docs: [ // resizable
      {
        type: "link",
        text: "Инструкция ADISA ADI MEGA.",
        link: "/resources/ADI-MEGA-CD-Rus.pdf"
      },
      {
        type: "link",
        text: "Инструкция по сборке ADISA ADI MEGA.",
        link: "/resources/ADI-MEGA-assembly.pdf"
      },
      {
        type: "link",
        text: "Сертификат соответствия.",
        link: "/resources/UA024C0555-20.pdf"
      },
      {
        type: "link",
        text: "Сертификат экспертизы типа (до 400кВт). ",
        link: "/resources/UATR012C0553-20.pdf"
      },
      {
        type: "link",
        text: "Сертификат экспертизы типа (более 400кВт).",
        link: "/resources/UATR012C0554-20.pdf"
      },
      {
        type: "link",
        text: "Разрешение ADISA.",
        link: "/resources/ADISA-dozvil.pdf"
      }
    ],
    table: [ // resizable
      [
        "ADI MEGA", "Ед.", "1200", "1600", "1800"
      ],
      [
        "Модуль состоит из котлов", " ", "ADI CD 650 2шт.", "ADI CD 850 2шт.", "ADI CD 950 2шт."
      ],
      [
        "Макс. мощность при 80/60ºC", "кВт", "1196.0", "1585.5", "1784,7"
      ],
      [
        "Мин. мощность при 50/30ºC", "кВт", "256.4", "341.5", "380.9"
      ],
      [
        "Макс. расход газа", "м3/ч", "114.9", "152.2", "171.9"
      ],
      [
        "Вес без воды", "кг", "970", "1090", "1090"
      ],
      [
        "Объем воды", "л", "240", "328", "328"
      ],
      [
        "Максимальное электропотребление", "Вт", "1660", "3300", "3600"
      ],
      [
        "Питание", "В", "230В", "380В", "380В"
      ],
      [
        "Ширина A", "мм", "2004", "2008", "2008"
      ],
      [
        "Глубина B", "мм", "940", "1083", "1083"
      ],
      [
        "Высота H", "мм", "1720", "1720", "1720"
      ],
      [
        "Диаметр дымохода", "мм", "2х350", "2х350", "2х350"
      ]
    ],
    preview: [ // resizable
      {
        images: [ // resizable
          {
            description: "",
            path: "/img/adimega1.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/adimega2.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/adimega3.jpg",
            alt: "",
            isWide: false
          }
        ],
        videos: [ // resizable
          {
            src: 'https://www.youtube.com/embed/DxukPBGlgb8',
            title: 'Инструкция по сборке модуля ADISA ADI MEGA'
          }
        ]
      }
    ]
  },
  // ========== condensa-pro ==========
  {
    name: "Condexa PRO",
    path: "condexa-pro",
    meta: {
      title: "Condexa PRO — Оборудование — ADISA",
      description: "description",
      keywords: "ADISA"
    },
    description: "Настенный конденсационный газовый котел КПД 108%",
    features: [ // resizable
      "Condexa PRO — это новое предложение от ADISA, представляющее собой модульную настенную конденсационную систему. Она может иметь открытую или закрытую камеру сгорания, состоять из одиночного котлоагрегата или работать в каскаде общей мощностью 1120 кВт.<br/><br/>",
      "・Линейка включает 8 моделей, представленных котельными модулями от 35 до 131 кВт.",
      "・Каждый котлоагрегат оснащен инновационным спиральным теплообменником запатентованной геометрической формы. Он состоит из концентрических гладких труб из нержавеющей стали пятиугольного сечения изнутри и круглого снаружи, увеличивающих теплообменную поверхность и обеспечивающих максимальную стойкость к коррозии.",
      "・В модели с приставкой P установлен насос с частотным регулированием. Базовая электроника управляет погодозависимой характеристикой, каскадной работой котельных модулей, функцией ведущего/ведомого устройства, автоматическим переключением режимов «Лето»/«Зима», а также прямой зоной и бойлером ГВС.",
      "・Вход 0-10 В или протокол Modbus позволяют добавить дистанционное управление.",
      "・Оптимальное управление процессом горения и высокий коэффициент модуляции – позволяют получить высокий КПД и низкие выбросы загрязняющих веществ (класс 6 в соответствии с нормативом UNI EN 297).",
      "・Непрерывный режим работы обеспечивается модульным исполнением: даже неисправность одного из котлоагрегатов не влияет на общее функционирование системы.",
      "・Автоматическая смена последовательности зажигания модулей.",
      "・Функция защиты от замерзания и заклинивания гарантируют работу при любых погодных условиях.",
      "・Возможность использования в качестве аксессуаров (дымоходы, сливы, подводы и пр. элементы обвязки) Украинских производителей.",
      "・Широкий ассортимент принадлежностей поможет упростить и ускорить установку, а также сделать комплектной каскадную систему."
    ],
    docsIntro: "Ссылки и документы:",
    docs: [ // resizable
      {
        type: "link",
        text: "Инструкция ADISA Condexa PRO.",
        link: "/resources/ADI-Condexa-PRO-Rus.pdf"
      },
      {
        type: "link",
        text: "Каскад ADISA Condexa PRO.",
        link: "/resources/ADI-Condexa-PRO-Cascade_Rus.pdf"
      }
    ],
    table: [ // resizable
      [
        "Condexa PRO", "Ед.", "35P", "50P", "57P", "70P", "90", "100", "115", "135"
      ],
      [
        "Тип камеры сгорания", " ", "открытая/закрытая", "открытая/закрытая", "открытая/закрытая", "открытая/закрытая", "открытая/закрытая", "открытая/закрытая", "открытая/закрытая", "открытая/закрытая"
      ],
      [
        "Способ создания тяги", " ", "дымоход/турбина", "дымоход/турбина", "дымоход/турбина", "дымоход/турбина", "дымоход/турбина", "дымоход/турбина", "дымоход/турбина", "дымоход/турбина"
      ],
      [
        "Максимальная тепловая мощность", "кВт", "38.0", "48.8", "61.9", "73.9", "97.4", "105.1", "121.1", "142.1"
      ],
      [
        "Минимальная тепловая мощность", "кВт", "9.0", "9.0", "14.0", "14.0", "19.4", "19.4", "22.4", "26.2"
      ],
      [
        "Наличие встроенного насоса", " ", "есть", "есть", "есть", "есть", "нет", "нет", "нет", "нет"
      ],
      [
        "КПД", "%", "109.5", "109.2", "109.2", "108.8", "108.9", "108.8", "108.8", "108.9"
      ],
      [
        "Диаметр дымохода (внутренний/внешний)", "мм", "80 или 60/100", "80 или 60/100", "80 или 60/100", "80 или 60/100", "110 или 110/160", "110 или 110/160", "110 или 110/160", "110 или 110/160"
      ],
      [
        "Габаритный размеры HxLxP", "мм", "1000х600х435", "1000х600х435", "1000х600х435", "1000х600х435", "1000х600х435", "1000х600х435", "1170х600х435", "1170х600х435"
      ],
      [
        "Вес", "кг", "58", "58", "64", "64", "69", "69", "84", "90"
      ]
    ],
    preview: [ // resizable
      {
        images: [ // resizable
          {
            description: "",
            path: "/img/condexaBoiler.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "",
            path: "/img/condexaDraw.jpg",
            alt: "",
            isWide: false
          },
          {
            description: "Пример каскада из котлов Condexa Pro",
            path: "/img/condexaPro.jpg",
            alt: "",
            isWide: true
          }
        ],
        videos: [ // resizable
          {
            src: "",
            title: ""
          }
        ]
      }
    ]
  }
  // ========== Add+ ==========
];
