export default {
  title: "ADISA - более 50-ти лет на рынке Европы и США",
  leftDescriptions: [ // +b resizable
    "ADISA Group была основана в 1961 Пьером Марти, ее действующим президентом.",
    "- ADISA Calefaccion (отопительное оборудование): производство высокоэффективных решений для отопления и производства горячей воды, средней и большой мощности, таких как котлы и модульные котельные;",
    "- ADISA Antilegionella гарантирует постоянное устранение бактерий в системе ГВС."
  ],
  rightDescriptions: [ // +b resizable
    "Добившись признания на европейском и американском рынках, компания заинтересовалась рынком стран Восточной Европы. В год своего 50-тилетнего юбилея ADISA Group вышла на рынок Украины. Развитие компании основано на политике постоянных инвестиций в исследования, на деловых взаимоотношениях с партнерами, а также на клиенте и его потребностях."
  ]
};
